:root {
  --brand100: #0E1724;
  --brand80: #212C3D;
  --brand60: #3B506D;
  --brand40: #a9b3c4;
  --brand30: #d9e4f7;
  --brand20: #8ef5f1;
  --brand0: #fff;
  --error: #d51010;

  --smallSize: 0.6rem;
  --normalSize: 0.8rem;
  --largeSize: 1rem;
  --smallPadding: 0.1rem 0.3rem ;
  --regularPadding: 0.3rem 0.5rem ;
  --largePadding: 0.5rem 0.5rem ;
}

@media (min-height: 800px) {
  :root {
    --regularPadding: 0.5rem 0.8rem ;
    --largePadding: 0.7rem 0.7rem ;
    --smallSize: 0.8rem;
    --normalSize: 1rem;
    --largeSize: 1.2rem;  
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background: linear-gradient(60deg, rgba(22,29,41,1) 0%, rgba(33,44,61,1) 30%, rgba(33,44,61,0.9) 100%);
  color: var(--brand0);
}

*:fullscreen, *:-webkit-full-screen, *:-moz-full-screen {
  background: rgb(22,29,41);
  background: linear-gradient(60deg, rgba(22,29,41,1) 0%, rgba(33,44,61,1) 30%, rgba(33,44,61,0.9) 100%);
  color: var(--brand0);
}

::backdrop {
  background: rgb(22,29,41);
  background: linear-gradient(60deg, rgba(22,29,41,1) 0%, rgba(33,44,61,1) 30%, rgba(33,44,61,0.9) 100%);
  color: var(--brand0);
}

.App {
  height: 100vh;
  display: grid;
  grid-template-rows: 56px 22px auto;
  grid-template-areas:
    "controls-area"
    "shaddow-area "
    "content-area"
  ;
}

.controls {
  grid-area: controls-area;
  color: var(--brand20);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 56px;
  padding: 4px 24px 0 0;
  z-index: 10;
}

.controlsBackground {
  background-image: url('./res/logo-icon.svg');
  background-repeat: no-repeat;
  background-size: 33px 45px;
  background-position: 16px;
  z-index: 10;
}

@media (min-width: 560px) {
  .controlsBackground {
    background-image: url('./res/logo-wide.svg');
    background-repeat: no-repeat;
    background-size: 165px 30px;
    background-position: 16px;
    z-index: 10;
  }
}

.shaddow-bar {
  grid-area: shaddow-area;
}

.shaddow {
  height: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.content {
  grid-area: content-area;
}

@media (min-width: 800px) {
  .mainScreen {
    display: grid;
    grid-template-columns: auto 300px;
    grid-template-areas:
      "screen-area   sidebar-area"
    ;
  }

  @media (min-height: 800px) {
    .mainScreen {
      display: grid;
      grid-template-columns: auto 350px;
      grid-template-areas:
        "screen-area   sidebar-area"
      ;
    }
  }
}

.video {
  color: white;
}

.pseudoLink, a {
  color: var(--brand20);
  cursor: pointer;
  text-decoration: none;
}

.pseudoLink:hover, a:hover {
  color: #10EDE5;
  text-decoration: underline;
}

.truncateContainer {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.truncatedElement {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .9rem;
}

.singleUnit {
  flex: 1;
  margin: 0 8px 16px 8px;
}

.fullUnit {
  margin: 0 8px;
  overflow-y: hidden!important;
}

@media (max-width: 1280px) {
  .optional {
    display: none!important;
  }
}


.melodistoCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--brand40);
  border-radius: 8px; /* this is more than the standard but that's necessary to be invisible */
  overflow-y: auto;
  scrollbar-color: var(--brand80) var(--brand30);
}

.melodistoCard::-webkit-scrollbar {
  width: .5rem;
}

.melodistoCard::-webkit-scrollbar-track {
  background: var(--brand30);
  border-radius: 0 0 4px 0;
}

.melodistoCard::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--brand80);
}

.melodistoCardHeader {
  border-radius: 4px 4px 0px 0px;
  background-color: var(--brand60);
  color: var(--brand0);
  padding: var(--regularPadding);
  font-size: var(--normalSize);
  font-weight: bold;
  min-width: 276px;
}

.melodistoCardBody {
  padding: var(--largePadding);
  color: var(--brand100);
  background-color: var(--brand40);
  display: grid;
  place-items: center;
}

.melodistoCardBodyAndFooter {
  padding: var(--largePadding);
  background-color: var(--brand40);
  border-radius: 0px 0px 4px 4px;
  color: var(--brand100);
}

.melodistoCardFooter {
  background-color: var(--brand40);
  color: var(--brand80);
  padding: var(--regularPadding);
  font-size: var(--largeSize);
  border-radius: 0px 0px 4px 4px;
}

.melodistoCardFooterVisual {
  border-radius: 0px 0px 4px 4px;
  background-color:#6c7e96;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.largeModal {
  width: 90vw;
  min-height: unset;
}

.maxHeightModal {
  width: 90vw;
  height: 90vh;
}

.switchLabel {
  margin-right: 8px;
  margin-left: 16px;
}

.switch-group .switch-handle {
  background-color: var(--brand60);
}

button, .btn:not(.switch-on, .switch-off, .switch-handle) { /*exclude the switch buttons */
  background-color: var(--brand100);
  color: var(--brand0);
  border: none;
  padding: 0.4rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: .8rem;
  border-radius: 4px;
  margin: 0 0 0 1rem;
  min-height: 34px;
}

button:hover, .btn:hover:not(.switch-on, .switch-off, .switch-handle) {
  background-color: var(--brand100);
  color: var(--brand20);
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: var(--brand0);
  background-color: var(--brand40);
  border-color: var(--brand40);
}

.btn-success:focus, .btn-success.focus {
  color: var(--brand0);
  background-color: var(--brand40);
  border-color: var(--brand0);
  box-shadow: 0 0 0 0.2rem var(--brand60);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--brand60);
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--brand60);
}

select {
  background-color: white;
  border: thin solid white;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

input[type=range]{
  -webkit-appearance: none;
  background-color: transparent;
}

input[type=range]::-webkit-slider-runnable-track {
  height: 8px;
  background: var(--brand60);
  border: none;
  border-radius: 8px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--brand80);
  margin-top: -4px;
}

input[type=range]::-moz-range-track {
  height: 8px;
  background: var(--brand60);
  border: none;
  border-radius: 8px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--brand80);
  margin-top: -4px;
}

.muted {
  color: var(--brand60);
  margin-left: 42px;
}

.invisible {
  display: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Chrome, Edge, Opera and Firefox */
}

.toastBody {
  background-color: var(--brand20);
  color: var(--brand80);
  text-align: center;
}

.recorderVideo {
  max-width: 100%;
  background-color: var(--brand60);
}

.audioPlayer {
  width: 100%;
  display: none;
}

/* BEGIN video and audio player */
.playBackVideo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lever {
  padding-top: 18px;
  width: 30%;
}

.slider {
  width: 100%;;
}

.label {
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
  color: var(--brand60);
}
/* END video and audio player */

/* BEGIN NotesRaw.js related */

.tab-content {
  overflow-y: auto;
  border-radius: 0 4px 4px 4px;
}

.tab-content>.active {
  display: block;
  height: 100%;
}

.nav-tabs .nav-link {
  margin-bottom: 0;
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: var(--brand0);
  background-color: var(--brand60);
}

.nav-tabs .nav-link.active {
  color: var(--brand80);
  background-color: var(--brand40);
  border-color: none;
}

.nav-tabs .nav-link.disabled {
  color: var(--brand80);
  background-color: var(--brand60);
  border-color: transparent;
}

/* END NotesRaw.js related */

.fadein {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
@keyframes fadein {
  0% { opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes fadein { /* Firefox */
  0% { opacity:0;}
  100% {opacity:1;}
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  0% { opacity:0;}
  100% {opacity:1;}
}
@-o-keyframes fadein { /* Opera */
  0% {opacity:0;}
  100% {opacity: 1;}
}

.fa-button {
  color: var(--brand0);
  cursor: pointer;
}

.fa-button-active {
  color: var(--brand0);
}

.fa-button:hover, .fa-button-active:hover {
  color: var(--brand20);
}

.dateSeparator {
  color: var(--brand80);
  font-size: var(--smallSize);
  margin: 8px 0px 0 4px;
  border-bottom: 1px solid var(--brand80);
}

/* Bootstrap Overrides */

.table-dark {
  background-color: var(--brand80);
  border-radius: 4px;
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: var(--brand80);
}

.melodistoModal {
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.melodistoModal .modal-content {
  background-color: var(--brand40);
  border: none;
  color: var(--brand100);
  box-shadow: 0px 0px 14px var(--brand80);
  height: 100%;
}

.melodistoModal .modal-header {
  background-color: var(--brand60);
  color: var(--brand0);
  align-items: center;
  border-bottom: none;
  padding: var(--regularPadding);
  font-size: var(--normalSize);
  font-weight: bold;
}

.melodistoModal * .modal-title {
  font-size: var(--normalSize);
  font-weight: bold;
  margin-left: 8px;
}

.melodistoModal * button.close {
  color: var(--brand0);
  opacity: 1;
  padding: 0;
  margin: 0;
  text-shadow: none;
}

.melodistoModal * button.close:hover {
  color: var(--brand20);
  background-color: var(--brand60);
}

.melodistoModal .modal-body {
  padding: 16px;
  overflow: hidden;
}

.melodistoModalTopAlign > div {
  display: flex;
  align-items: flex-start;
}
