.topModal {
  width: 97vw;
  max-width: 97vw;
}

.pianoModalBody {
  background-color: white;
  padding: 0!important;
}

.pianoContainer {
  height: 258px;
  display: grid;
  place-items: center;
  position: relative;
}

.pianoUnit {
  height: 80px;
}

.piano {
  height: 100%;
  width: 100%;
  max-width: 970px;
  position: absolute;
}

.musicCanvas {
  display:flex;
}

.trebleStaveContainer {
  width: 100%;
  background-color: #fff0;
}

.bassStaveContainer {
  width: 100%;
  background-color: #fff0;
  display: flex;
  justify-content: flex-end;
}

.controls {
  background-color: var(--brand40);
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.keySignatureSelector {
  width: 12rem;
  height: 2rem;
  border: none;
  margin: 1px;
  padding: 2px;
  padding-left: 4px;
  border-radius: 4px;
}

.localPiano {
  z-index: 4000;
}

.localKeyboard {
  opacity: .3;
  top: 0px;
  z-index: 4000;
}

.hint {
  position: absolute;
  bottom: 8px;
  color: var(--brand40);
  font-size: var(--smallSize);
}
