.audioMeter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.volumeIndicator {
  height: 10px;
  border-radius: 4px;
  background-color: var(--brand30);
  width: 127px;
}

.volumeBar {
  height: 10px;
  border-radius: 4px;
  background-color: var(--brand80);
}

.volumeBarClipped {
  height: 10px;
  border-radius: 4px;
  background-color: red;
}
