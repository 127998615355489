.silverScreen {
  display: grid;
  justify-items: center;
  height: calc(100vh - 80px);
}

.sideBar {
  grid-area: sidebar-area;
  margin: 0 8px;
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: calc(100vh - 80px);
}

.smallVideo {
  grid-area: sidebar-area;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  background-color: var(--brand60);
  z-index: 10;
}

.largeVideo {
  grid-area: screen-area;
  width: 100%;
  max-width: 100%;
  max-height: calc(100vh - 90px);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  z-index: 9;
}

.fullScreenVideo {
  position: absolute;
  top: 0;
  left: 0;
}

.modeButton {
  position: absolute;
  bottom: 16px;
  left: 16px;
  background-color: var(--brand60);
  padding: 16px;
  z-index: 30;
  border-radius: 40px;
  width: 52px;
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.musicStandContainer {
  width: 100%;
}

.waiting {
  width: 100%;
  height: 150px;
  border-radius: 4px;
}

.waitingMessage1 {
  color: var(--brand0);
  text-align: center;
  margin-bottom: 8px;
}

.waitingMessage2 {
  color: var(--brand0);
  text-align: center;
  margin-bottom: 32px;
}

.controlsContainer {
  position: absolute;
}

.mediaSettingsButton {
  display: grid;
  place-items: center;
  position: absolute;
  top: 120px;
  left: 6px;
  width: 24px;
  height: 24px;
  z-index: 20;
}

.mediaSettingsButton:hover, .microphoneButton:hover {
  background-color: var(--brand60);
  z-index: 20;
}

.microphoneButton {
  display: grid;
  place-items: center;
  position: absolute;
  top: 4px;
  left: 6px;
  width: 24px;
  height: 24px;
  z-index: 20;
}
