.activities {
  background-image: url(../res/nav_nachos@2x.png);
  background-position: left 0 bottom 0;
  background-repeat: no-repeat;
  background-size: 300px 600px;
  overflow-y: auto;
  scrollbar-color: var(--brand80) var(--brand30);
  height: 100%;
  border-radius: 0 4px 4px 4px;
}

.activities::-webkit-scrollbar {
  width: .5rem;
}

.activities::-webkit-scrollbar-track {
  background: var(--brand30);
  border-radius: 4px;
}

.activities::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--brand80);
}

.activity {
  align-items: center;
  margin-bottom: 16px;
}

.activityText {
  margin: .3rem 0;
  padding: var(--regularPadding);
  border-radius: 8px;
  font-size: var(--normalSize);
  color: var(--brand0);
  background: var(--brand80);
  overflow-wrap: break-word;
}

.docsUsed {
  margin-top: .7rem;
}

.docsUsedList {
  padding-left: 9px;
  overflow-wrap: break-word;
}

.docsUsedList li {
  list-style-type: '- ';
  margin: 4px 0;
}

.activityNotesRaw {
  white-space: pre-line;
}