.sideBar {
  grid-area: sidebar-area;
  margin: 0 8px;
  display: grid;
  grid-template-rows: auto auto 1fr;
  max-height: calc(100vh - 85px);
}

.homeBoxes {
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: calc(100vh - 85px);
}

.visual {
  height: 120px;
  border-radius: 0px 0px 4px 4px;
  background-color:#6c7e96;
  padding: 24px;
}

@media (min-height: 800px) {
  .visual {
    height: 160px;
    border-radius: 0px 0px 4px 4px;
    background-color:#6c7e96;
    padding: 24px;
  }
}

.calleContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.startButton {
  width: 300px;
  margin: 0;
  font-size: var(--largeSize);
}

.joinButton {
  width: 300px;
  margin: 0;
  font-size: var(--largeSize);
}

.calleeName {
  width: 300px;
  border: none;
  margin: 1px;
  padding: 2px;
  padding-left: 4px;
  border-radius: 2px;
}

.recommendedContainer {
  place-items: normal;
}

.recommended * a {
  color: var(--brand80);
  text-decoration: underline;
}

.mediaSettingsButton {
  color: var(--brand0);
  margin: var(--largePadding);
  font-size: var(--smallSize);
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
}

.mediaSettingsButton:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.mediaSettingsButton:hover * {
  color: var(--brand0);
  text-decoration: underline;
}
