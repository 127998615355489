.loginContainer {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
@keyframes fadein {
  0% { opacity:0;}
  90% { opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes fadein { /* Firefox */
  0% { opacity:0;}
  100% {opacity:1;}
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  0% { opacity:0;}
  90% { opacity:0;}
  100% {opacity:1;}
}
@-o-keyframes fadein { /* Opera */
  0% {opacity:0;}
  90% { opacity:0;}
  100% {opacity: 1;}
}

.loginButtons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.googleLoginContainer {
  display: flex;
  align-items: center;
  justify-content: center;  
}

.fbLoginButton {
  border-radius: 2px;
  background-color: rgb(24, 119, 242);
  color: var(--brand0);
  padding: var(--smallPadding);
  font-size: var(--largeSize);
  min-width: 240px;
  height: 50px;
  margin: 0;
}

.signInText {
  color: var(--brand0);
  padding: var(--largePadding);
}