.canvas {
  position: absolute;
  cursor: crosshair;
}

.drawing {
  border: 2px dashed black;
  opacity: 0.25;
  position: absolute;
  cursor: crosshair;
}

.hostRectangle {
  border: 1px dashed black;
  opacity: 0.25;
  background-color: pink;
  position: absolute;
  cursor: crosshair;
}

.guestRectangle {
  border: 1px dashed black;
  opacity: 0.25;
  background-color: yellow;
  position: absolute;
  cursor: crosshair;
}
