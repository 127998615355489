.musicStandContainer {
  width: 100%;
}

.musicStandContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--brand40);
  border-radius: 8px; /* this is more than the standard but that's necessary to be invisible */
}

.musicStand {
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 124px);
  position: relative;
  display: flex;
  justify-content: center;
  padding: 8px 0 0 0;
  overflow-y: auto;
  scrollbar-color: var(--brand80) var(--brand30);
}

.musicStand::-webkit-scrollbar {
  -webkit-appearance: none;
}

.musicStand::-webkit-scrollbar:vertical {
  width: .5rem;
  border-radius: 0 0 0 4px ;
  background-color: var(--brand30);
}

.musicStand::-webkit-scrollbar:horizontal {
  height: 8px;
}

.musicStand::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--brand80);
}

.remoteCoverDimensions {
  position: fixed;
  width: 100%;
  bottom: 16px;
  right: 320px;
  text-align: right;
  padding: 8px;
}

.remoteCover {
  opacity: 0.8;
  background-color: var(--brand80);
  color: var(--brand0);
  font-size: var(--smallSize);
  z-index: 3000;
}

@media (min-height: 800px) {
  .remoteCoverDimensions {
    bottom: 16px;
    width: 100%;
    right: 370px;
  }
}

.pdfDocContainer {
  margin-bottom: 4px;
  position: absolute;
}

.pdfPageContainer {
  margin-bottom: 4px;
}

.pdfPageContainerBlank {
  opacity: 0;
}

.scrollCover {
  background-color: var(--brand80);
  opacity: 0.1;
  position: fixed;
  z-index: 3000;
}

.headerBar {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;

  justify-content: space-between;
}

.titleBar {
  display: flex;
  flex: 0 1 50vw;
  max-width: 720px; 
}

.docNameContainer {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.docName {
  margin: 0 16px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttonBar {
  display: flex;
  flex: 0 0 auto;
  margin-left: 12px;
}

.separator {
  margin: 0 24px;
  color: var(--brand40);
}

.shareMessage {
  color: var(--brand0);
  background: var(--brand60);
  font-size: var(--largeSize);
  display: flex;
  padding: 6px 12px;
  align-items: center;
  border-radius: 4px;
  margin: 4px;
}

.shareInfo {
  font-size: var(--smallSize);
  padding: 6px 12px;
}
