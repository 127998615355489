.documentBrowserContainer {
  /* min-height: calc(100vh - 560px); */
  display: grid;
  grid-template-rows: auto 1fr;
  height: inherit;
}

.tableFunctions {
  display: flex;
  justify-content: space-between;
}

.searchBoxContainer {
  width: 50%;
}

.searchBox {
  border: none;
  padding: 0 .3rem;
  font-size: .8rem;
  border-radius: 4px;
  min-height: 34px;
  width: 100%;
}

.actions {
  display: flex;
  zoom: 1.1;
}

.buttonFrame {
  width: 32px;
  text-align: center;
}

.documentList {
  font-size: .75rem;
  overflow-y: auto;
  border-radius: 4px;
  scrollbar-color: var(--brand80) var(--brand30);
}

.documentList::-webkit-scrollbar {
  width: .5rem;
}

.documentList::-webkit-scrollbar-track {
  background: var(--brand30);
}

.documentList::-webkit-scrollbar-thumb {
  background: var(--brand80);
  border-radius: 4px;
}

.docTableHeader:nth-child(2) {
  width: 20%;
}

.docTableHeader:nth-child(3), .docTableHeader:nth-child(4) {
  width: 10%;
}

.originHint {
  display: none;
  color: var(--brand0);
  margin-left: 32px;
  font-size: var(--smallSize);
}

.docTableCell {
  vertical-align: middle!important;
}

@media (max-width: 1140px) {
  .docTableCell:nth-child(2), .docTableCell:nth-child(3), .docTableCell:nth-child(4),
  .docTableHeader:nth-child(2), .docTableHeader:nth-child(3), .docTableHeader:nth-child(4) {
    display: none;
  }
  .originHint {
    display: block;
  }
}
