.recorder {
  display: flex;
  position: relative;
}

.recorderVideo {
  max-height: 220px;
  height: 220px;
  max-width: 100%;
  background-color: var(--brand60);
}

.countDown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-weight: bold;
  -webkit-text-stroke: 1px var(--brand80);
  color: var(--brand0);
  font-size: 80px;
  z-index: 100;
}

.recordingBlinker {
  position: absolute;
  font-weight: bold;
  color: red;
  -webkit-text-stroke: .1px black;
  margin-left: 8px;
  animation: blinker 1s linear infinite;
  z-index: 100;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.playBackVideo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileNameContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 48px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.shareOption {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.shareOption * label{
  width: 300px;
}

.fileName {
  width: 300px;
  border: none;
  margin: 1px;
  padding: 2px;
  padding-left: 4px;
  border-radius: 2px;
}

.cancelButton, .saveButton {
  width: 130px;
}

.cancelButton {
  background-color: var(--brand30);
  color: var(--brand80);
  margin: 0;
}

.cancelButton:hover {
  background-color: var(--brand30);
  border: 1px solid var(--brand40);
  color: var(--brand60);
}
