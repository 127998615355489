.notesTabContainer {
  margin: 0;
  overflow-y: hidden;
  display: grid;
  height: 100%;
  box-sizing: border-box;
  grid-template-rows: auto 1fr;
}

.notesContainer {
  margin: 0;
}

.notesTab {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  border-radius: 0 4px 0 0;
}

.notesDocsUsed {
  border-radius: 0px 0px 4px 4px;
  background-color: var(--brand80);
  padding: var(--regularPadding);
  font-size: var(--normalSize);
}

.docsUsedList {
  padding-left: 9px;
  overflow-wrap: break-word;
  max-width: 250px;
}

.docsUsedList li {
  list-style-type: '- ';
  margin: 4px 0;
  color: var(--brand20);
}

.notesGrid {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: var(--largePadding);
}

.notesArea {
  padding-left: 8px;
  margin-top: 4px;
  height: 100%;
  background-color: var(--brand80);
  border-radius: 4px 4px 0 0;
}

textarea {
  scrollbar-color: var(--brand30) var(--brand80);
  border-radius: 0 4px 4px 0;
}

textarea::-webkit-scrollbar {
  width: .5rem;
}

textarea::-webkit-scrollbar-track {
  background: var(--brand80);
  border-radius: 0 4px 4px 0;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--brand30);
}

.notesArea textarea {
  resize: none;
  width: 100%;
  height: 98%;
  outline: none;
  overflow: auto;
  font-size: var(--normalSize);
  border: none;
  background-color: var(--brand80);
  color: var(--brand0);
}

.notesStatus {
  background-color: var(--brand40);
  color: var(--brand80);
  font-size: var(--smallSize);
  padding: 0 8px;
}


.tabs {
  position: relative;
  clear: both;
}

.content {
  background: var(--brand40);
  position: absolute;
  top: 29px;
  left: 0;
  right: 0;
  border-radius: 0 4px 4px 4px;
  overflow-y: auto;
  scrollbar-color: var(--brand80) var(--brand40);
}

.content::-webkit-scrollbar {
  width: .5rem;
}

.content::-webkit-scrollbar-track {
  background: var(--brand40);
  border-radius: 4px;
}

.content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--brand80);
}

@media (min-height: 800px) {
  .tabs {
    position: relative;
    clear: both;
  }
  
  .content {
    background: var(--brand40);
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    border-radius: 0 4px 4px 4px;
    overflow-y: auto;
    scrollbar-color: var(--brand80) var(--brand40);
  }
}

.tab {
  float: left;
}

.tabBackground {
  border-bottom: 0!important;
  overflow-y: clip;
}

.tabLabel {
  border-radius: 4px 4px 0px 0px;
  background-color: var(--brand60);
  color: var(--brand0);
  padding: var(--regularPadding);
  font-size: var(--normalSize);
  margin-right: 4px;
  cursor: pointer;
  background-color: var(--brand40);
  color: var(--brand80);
  z-index: 2;
  cursor: default;
}
