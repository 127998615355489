.shareeList {
  max-height: 240px;
  overflow-y: auto;
  scrollbar-color: var(--brand80) var(--brand40);
}

.shareeList::-webkit-scrollbar {
  width: .5rem;
}

.shareeList::-webkit-scrollbar-track {
  background: var(--brand40);
  border-radius: 0 0 4px 0;
}

.shareeList::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--brand80);
}

.sharingTitle {
  padding: var(--regularPadding);
  margin-left: 0.3rem;
  color: var(--brand80);
}

.sharingHint {
  padding: var(--regularPadding);
  font-size: var(--smallSize);
  margin-left: 0.3rem;
  color: var(--brand60);
}

.selectContainer {
  display: flex;
  padding: var(--regularPadding);
  margin: .3rem 0;
}

.nameTag {
  display: flex;
  padding: var(--regularPadding);
  margin: .3rem 0;
  border-radius: 8px;
  background-color: var(--brand40);
  opacity: .8;
}

.nameTag:hover {
  opacity: 1;
}

.nameTagName {
  color: var(--brand100);
  font-size: var(--normalSize);
}

.nameTagEmail {
  color: var(--brand60);
  font-size: var(--smallSize);
}

.nameTagAvatar {
  display: flex;
  border-radius: 50%;
  color: var(--brand0);
  background-color: var(--brand80);
  align-items: center;
  margin: 0.3rem 1.2rem .3rem 0;
  font-size: 1.125rem;
  height: 2.25rem;
  width: 2.25rem;
  justify-content: center;
  justify-items: center;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.nameTagData {
  flex: 1 1 200px;
}

.nameTagAction {
  display: flex;
  align-items: center;
}

