.error {
  border: 2px dotted red;
  z-index: 100000;
}

.valid {
  z-index: 100000;
}

.validHigher {
  z-index: 100001;
}

.buttonBar {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formColumn {
  flex: 1 1 350px;
}