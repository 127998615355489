
.controlsContainer {
  display: flex;
}

.avatarImage {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 12px;
}

.controlButton {
  display: flex;
  background-color: var(--brand100);
  color: var(--brand0);
  border: none;
  padding: 0.4rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: .8rem;
  border-radius: 4px;
  margin: 0 0 0 2px;
  min-height: 34px;
}

.controlButton:hover {
  background-color: var(--brand60);
  color: var(--brand20);
}

.endButton {
  background-color: #bd0b0b;
  font-weight: bold;
}

.endButton:disabled {
  background-color: gray;
  font-weight: bold;
  color: var(--brand40);
}

.endButton:disabled:hover {
  background-color: gray;
  font-weight: bold;
  color: var(--brand40);
}

.recordBullet {
  margin-right: 8px;
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.stopBullet {
  margin-right: 8px;
  height: 10px;
  width: 10px;
  background-color: var(--brand0);
  border-radius: 2px;
  display: inline-block;
}

.stopBulletBlink {
  margin-right: 8px;
  height: 10px;
  width: 10px;
  background-color: var(--brand0);
  border-radius: 2px;
  display: inline-block;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
