.draggablePlayer {
  width: 500px;
  max-width: 100vw;
  background-color: var(--brand40);
  border-radius: 4px;
  box-shadow: 1px 1px 8px 4px var(--brand80);
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
  z-index: 20;
}

.draggableTitle {
  display: flex;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  background-color: var(--brand60);
  color: var(--brand0);
  align-items: center;
  border-bottom: none;
  padding: 0 0 0 16px;
  font-size: var(--smallSize);
  font-weight: bold;
  cursor: move;
}

.closeButton {
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--brand60);
  font-weight: bold;
}

.closeButton:hover {
  background-color: var(--brand60);
  color: var(--brand20);
}

.seek {
  width: 100%;
  margin: 36px 0 8px 0;
  padding: 0 16px;
}

.seekSlider {
  width: 100%;
  background-color: var(--brand40);
}

.balanceLabel {
  display: flex;
  justify-content: center;
  color: var(--brand60);
}

.radioLabel {
  display: flex;
  color: var(--brand60);

}

.playControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-top: 8px;
}

.playButton {
  background-color: var(--brand80);
  color: var(--brand0);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.playButton:hover {
  color: var(--brand20);
}

.remotePlayerOptions {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-top: 2px solid var(--brand60);
  margin-top: 32px;
  padding: 0 16px;
}

input.seekSlider[type="range"] { 
  -webkit-appearance: none;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  height: 16px;
}

input.seekSlider[type="range"]::-webkit-slider-runnable-track {
  background: var(--brand30);
  height: 16px;
  border-radius: 0 !important;
}

input.seekSlider[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  border-radius: 0 !important;
  margin-top: 0 !important;
  height: 16px;
  width: 16px;
  background: var(--brand100);
  box-shadow: -100vw 0 0 100vw var(--brand60);
}

input.seekSlider[type="range"]::-moz-range-track {
  border: none;
  border-radius: 0 !important;
  height: 16px;
  background: var(--brand30);
}

input.seekSlider[type="range"]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border: none;
  border-radius: 0 !important;
  background: var(--brand100);
  box-shadow: -100vw 0 0 100vw var(--brand60);
  box-sizing: border-box;
}

.radioItem {
  position: relative;
  padding: 0;
  margin: 10px 0 0;
}

.radioItem input[type='radio'] {
  display: none;
}

.radioItem label {
  color: var(--brand60);
  font-weight: normal;
  cursor: pointer;
}

.radioItem label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid var(--brand60);
  background-color: transparent;
}

.radioItem input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: var(--brand80);
}